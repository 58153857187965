import VideoServiceBase from "markdown-it-block-embed/lib/services/VideoServiceBase";

class QuizService extends VideoServiceBase {
  getDefaultOptions() {
    return {
      width: "100%"
    };
  }

  extractVideoID(reference) {
    return reference;
  }

  getVideoUrl(videoID) {
    return videoID;
  }
}

export default QuizService;
