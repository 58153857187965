export default {
  BWS_URL: process.env.VUE_APP_BWS_URL
    ? process.env.VUE_APP_BWS_URL
    : "https://portfolioconstructionforum.edu.au",
  BUDS_URL: process.env.VUE_APP_BUDS_URL
    ? process.env.VUE_APP_BUDS_URL
    : "https://buds.brillient.com.au",
  BEM_URL: process.env.VUE_APP_BEM_URL
    ? process.env.VUE_APP_BEM_URL
    : "https://programs.portfolioconstructionforum.edu.au",
  BDB_URL: process.env.VUE_APP_BDB_URL
    ? process.env.VUE_APP_BDB_URL
    : "https://badges.portfolioconstructionforum.edu.au"
};
