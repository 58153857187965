<template>
  <div id="editor">
    <editor />
  </div>
</template>

<script>
import Editor from "@/components/Editor.vue";

export default {
  name: "app",
  components: {
    Editor
  }
};
</script>

<style></style>
