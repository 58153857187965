import VideoServiceBase from "markdown-it-block-embed/lib/services/VideoServiceBase";

class VideoService extends VideoServiceBase {
  getDefaultOptions() {
    return {
      width: 760,
      height: 429
    };
  }

  extractVideoID(reference) {
    return reference;
  }

  getVideoUrl(videoID) {
    return videoID;
  }
}

export default VideoService;
