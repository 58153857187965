import VideoServiceBase from "markdown-it-block-embed/lib/services/VideoServiceBase";

class BuzzsproutService extends VideoServiceBase {
  getDefaultOptions() {
    return {
      width: "100%",
      height: 200
    };
  }

  extractVideoID(reference) {
    return reference;
  }

  getVideoUrl(videoID) {
    return "https://www.buzzsprout.com/1009612/" + videoID + "?iframe=true";
  }

  getEmbedCode(videoID) {
    const containerClassNames = [];
    if (this.env.options.containerClassName) {
      containerClassNames.push(this.env.options.containerClassName);
    }

    const escapedServiceName = this.env.md.utils.escapeHtml(this.name);
    containerClassNames.push(
      this.env.options.serviceClassPrefix + escapedServiceName
    );

    const iframeAttributeList = [];
    iframeAttributeList.push(["type", "text/html"]);
    iframeAttributeList.push(["src", this.getFilteredVideoUrl(videoID)]);
    iframeAttributeList.push(["frameborder", 0]);
    iframeAttributeList.push(["scrolling", "no"]);

    if (this.env.options.outputPlayerSize === true) {
      if (this.options.width !== undefined && this.options.width !== null) {
        iframeAttributeList.push(["width", this.options.width]);
      }
      if (this.options.height !== undefined && this.options.height !== null) {
        iframeAttributeList.push(["height", this.options.height]);
      }
    }

    if (this.env.options.allowFullScreen === true) {
      iframeAttributeList.push(["webkitallowfullscreen"]);
      iframeAttributeList.push(["mozallowfullscreen"]);
      iframeAttributeList.push(["allowfullscreen"]);
    }

    const iframeAttributes = iframeAttributeList
      .map(pair =>
        pair[1] !== undefined ? `${pair[0]}="${pair[1]}"` : pair[0]
      )
      .join(" ");

    return (
      `<div class="${containerClassNames.join(" ")}">` +
      `<iframe ${iframeAttributes}></iframe>` +
      `</div>\n`
    );
  }
}

export default BuzzsproutService;
